import React, { useState, useEffect, useContext } from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Twitter from "../assets/SVG/Twitter.svg";
import LinkedIn from "../assets/SVG/LinkedIn.svg";
import "./css/wordDisplay.css";
import { envVar } from "../Env/localEnv";
import AppContext from "../assets/Routes/AppContext";
import axiosInstance from "../configs/axios";

export default function DispalyWord() {
	const { logoutAlert, setLogoutAlert } = useContext(AppContext);
	let { id } = useParams();
	const [blog, setBlog] = useState(false);
	const [img, setImg] = useState();

	useEffect(() => {
		getBlog();
	}, []);

	const getBlog = async () => {
		try {
			const res = await axiosInstance.get(`${envVar.apiUrl}/get-blog/${id}`);
			setBlog(res.data.blog);
			// fetchImage(res.data.blog.image)
		} catch (error) {
			console.log(error);
		}
	};

	// const fetchImage = async (imageName) => {
	// 	const res = await axios.get(`${envVar.apiUrl}/blog/load-image/${imageName}`);
	// 	console.log(res);
	// 	const imageBlob = await res.blob();
	// 	const imageObjectURL = URL.createObjectURL(imageBlob);
	// 	setImg(imageObjectURL);
	// };

	const copyLink = () => {
		navigator.clipboard.writeText(envVar.url + "/blog/" + blog.id);
		var tooltip = document.getElementById("myTooltip");
		tooltip.style.visibility = "visible";
		tooltip.style.opacity = 1;
		tooltip.innerHTML = "Link Copied";
	};

	const hideAlert = () => {
		if (logoutAlert) {
			setLogoutAlert(false);
		}
	};

	const outFunction = () => {
		var tooltip = document.getElementById("myTooltip");
		setTimeout(() => {
			tooltip.style.visibility = "hidden";
			tooltip.style.opacity = 0;
			tooltip.innerHTML = "";
		}, 300);
	};
	
	return (
		<div>
			<Header
				title={blog ? blog.name : ""}
				index={false}
				subTitle={blog ? blog.title : ""}
			/>
			<div className="content-div-Word" onClick={hideAlert}>
				<div className="sub-div-word">
					<div className="content-div-left">
						{blog && (
							<div className="content-left-wrapper">
								<p
									className="content-div-title"
									style={{
										fontSize: "30px",
										fontWeight: "600",
										color: "black",
									}}>
									{"What is " + blog.name + "?"}
								</p>

								<div>
									{ blog.image && (
										<div
											style={{
												height: "70%",
												width: "70%",
												margin: "auto",
											}}>

											<img
												style={{
													height: "100%",
													width: "100%",
												}}
												alt="blog"
												src={`${envVar.apiStorageUrl}/blog/${blog.image}`}
											/>
										</div>
									)}

									<div>
										<p className="p-text">
											{ blog.description }
										</p>
									</div>
								</div>
							</div>
						)}
						
						<div>
							<div className="div-note">
								<div>
									<p className="lower-h">
										Shifl digital Supply Chain Platform: new era of
										efficency
									</p>
									<p
										style={{
											fontSize: "18px",
											color: "#4A4A4A",
										}}
										className="lower-paragraph"
									>
										Join and stramline communincation & data mangement
										while enhance collaboration between internal &
										external parties.
									</p>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										{/* <button
											style={{
											padding: "1px 15px",
											backgroundColor: "#0171a1",
											borderRadius: "4px",
											border: "none",
											height: 40,
											display: "flex",
											alignItems: "center"
											}}
											onClick={}
										>
											<p
											style={{ color: "#fff", fontWeight: "500", fontSize: "14px" }}
											>
											Get Started
											</p>
										</button> */}

										<a
											href="https://shifl.com/register"
											target="_blank"
											style={{
												padding: "1px 15px",
												backgroundColor: "#0171a1",
												borderRadius: "4px",
												border: "none",
												height: 40,
												display: "flex",
												alignItems: "center",
												width: "auto",
												color: "#fff",
												fontSize: "14px",
											}}
										>
											Get Started
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="content-div-right">
						<div
							style={{
								padding: "10px 24px",
								backgroundColor: "#fff",
								borderRadius: 4,
								border: "1px solid #EBF2F5",
							}}>

							<p className="right-Heading">Share This Post</p>
							<div>
								<Link to="https://www.facebook.com/" target="_blank" className="icon-div">
									<span
										className="material-icons"
										style={{
											fontSize: 16,
											color: "#1778F2",
										}}>
										facebook
									</span>
									<p
										className="icon-txt"
										style={{
											color: "#1778F2",
										}}>
										Share on Facebook
									</p>
								</Link>
								<Link to="https://www.twitter.com/" target="_blank" className="icon-div">
									<img alt="icon" src={Twitter} height={12} />
									<p
										className="icon-txt"
										style={{
											color: "#00ACEE",
										}}>
										Share on Twitter
									</p>
								</Link>
								<Link to="https://www.linkedin.com/" target="_blank" className="icon-div">
									<img
										alt="icon"
										src={LinkedIn}
										height={12} />
									<p
										className="icon-txt"
										style={{
											color: "#0072B1",
										}}>
										Share on LinkedIn
									</p>
								</Link>
							</div>
							<div style={{ marginBottom: "16px" }}>
								<p className="label-txt">POST LINK</p>
								<div
									style={{
										border: "1px solid #B4CFE0",
										paddingLeft: 12,
										borderRadius: "4px",
										height: 40,
										display: "flex",
										alignItems: "center",
									}}>
									<p
										style={{
											color: "#4a4a4a",
											fontSize: 14,
											fontFamily: "'Inter', sans-serif",
											margin: 0,
										}}
									>
										{ envVar.url + "/blog/" + blog.id }
									</p>
								</div>

								{/* <button className="copy-btn" onClick={copyLink}>
								<p>Copy Link</p>

								<span class="tooltiptext" id="myTooltip">Copy to clipboard</span>
									Copy Link
								</button> */}
							</div>

							<div
								className="tooltip-copy"
								onMouseOut={outFunction}
							>
								<button onClick={copyLink}>
									<span
										className="tooltiptext"
										id="myTooltip"
										style={{ visibility: "hidden" }}
									>
										Link Copied
									</span>
									Copy text
								</button>
							</div>
						</div>
						<div
							style={{
								padding: "10px 24px",
								backgroundColor: "#fff",
								marginTop: 20,
								borderRadius: 4,
								border: "1px solid #EBF2F5",
							}}
						>
							<p className="right-Heading">
								Related Help Articles
							</p>
							<div>
								<Link>
									<p className="side-link">
										Custom Exams: Need an Expert
									</p>
								</Link>
								<Link>
									<p className="side-link">
										Orginal Bills of Landing: What you Need
										to Know
									</p>
								</Link>
							</div>
						</div>
						<div
							style={{
								padding: "10px 24px",
								backgroundColor: "#fff",
								marginTop: 20,
								borderRadius: 4,
								border: "1px solid #EBF2F5",
							}}
						>
							<p className="right-Heading">Resources</p>
							<div>
								<Link>
									<p className="side-link">
										Automated Expert System-CBP
									</p>
								</Link>
								<Link>
									<p className="side-link">
										Automated Manifest System
									</p>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}