// AuthProvider.js

import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export function AuthProvider({ children }) {
	const [user, setUser] = useState(null);
	const login = () => {
		setUser({ name: "John Doe" });
	};

	const logout = () => {
		setUser(null);
		localStorage.removeItem("user_token");
		localStorage.removeItem("user_details");
	};

	return (
		<AuthContext.Provider value={{ user, setUser, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
}

export function useAuth() {
	return useContext(AuthContext);
}
