import React, { useState, useEffect, useMemo, useContext } from "react";
import Logo from "../../assets/SVG/shifl-logo-white.svg";
import { Link, useNavigate } from "react-router-dom";
import Building from "../../assets/SVG/building.svg";
import Search from "../../assets/SVG/Search.svg";
import "../css/header.css";
import { useAuth } from "../../assets/Routes/AuthProvider";
import AppContext from "../../assets/Routes/AppContext";

export default function Header({
	title,
	index,
	subTitle,
	setSearch,
	searchBlogs,
}) {
	const navigate = useNavigate();
	const { logout, user } = useAuth();
	const { logoutAlert, setLogoutAlert } = useContext(AppContext);

	const [showMobileNav, setShowMobileNav] = useState(false);
	const [showLanguage, setshowLanguage] = useState(false);
	const [currentUser, setCurrentUser] = useState({		
		id: 0,
		name: "",
		address: "",
	});

	const logoutUser = async () => {
		localStorage.removeItem("user");
		// navigate("/login", { replace: true });
		navigate("/", { replace: true });
		logout();
	};

	const hideAlert = () => {
		if (logoutAlert) {
			setLogoutAlert(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		let user = localStorage.getItem("user_details");
		let parseUser = JSON.parse(user);
		setCurrentUser({ 
			...currentUser, 
			id: parseUser ? parseUser.default_customer.id : null,
			name: parseUser ? parseUser.default_customer.company_name : '',
			address: parseUser ? parseUser.default_customer.address : ''
		});
	};

	return (
		<div>
			<div className={`header ${title !== 'Product Demo Videos' ? 'glossary-page' : ''}`} onClick={hideAlert}>
				<div className="header-row">
					<div style={{ display: "flex", alignItems: "center" }}>
						<Link to={"/"}>
							<img
								alt="logo"
								src={Logo}
								height={37}
								width={150}
								className="shifl-logo"
							/>
						</Link>
					</div>
					<div className="header-row-right">
						{index ? (
							<Link to="/tutorial"
								className="internal-btn">
								<p className="language">Video Tutorial</p>
							</Link>
						) : (
							<Link to="/glossary"
								className="internal-btn">
								<p className="language">Glossary</p>
							</Link>
						)}

						<LogoutButton
							showLanguage={showLanguage}
							setshowLanguage={setshowLanguage}
							user={user}
							logoutUser={logoutUser}
							currentUser={currentUser}
						/>
					</div>
					<button
						className="custom-btn"
						onClick={() => setShowMobileNav(!showMobileNav)}>
						<span className="material-icons mobi-icon">menu</span>
					</button>
					{showMobileNav && (
						<div className="mobile-nav">
							{index ? (
								<Link to="/tutorial"
									className="internal-btn"
									style={{ marginRight: 20 }}>
									<p className="language">Video Tutorial</p>
								</Link>
							) : (
								<Link to="/glossary"
									className="internal-btn"
									style={{ marginRight: 20 }}>
									<p className="language">Glossary</p>
								</Link>
							)}
						</div>
					)}
				</div>
				{index == false && (
					<Link to="/Glossary" className="header-link">
						<span className="material-icons header-link-text">
							chevron_left
						</span>
						<p
							style={{
								fontSize: 16,
								color: "#fff",
								fontWeight: "500",
							}}
							className="p-back"
						>
							Go Back to Glossary
						</p>
					</Link>
				)}

				<div
					className="header-btm-div"
					style={{ marginTop: showMobileNav ? 30 : 0 }}
				>
					<p className={`heading-h ${index === false ? 'glossary-details-h' : ''}`}>
						{ title }
					</p>
					<p className={`header-p ${index === false ? 'glossary-details-p' : ''}`} 
						style={{ marginBottom: "15px", display: `${subTitle === '' ? 'none' : ''}` }}>
						{index
							? "Boost Your Supply Chain Terminology"
							: subTitle}
					</p>
					{index && (
						<div className="btm-search-div">
							<div className="search-Header-btn">
								<img
									src={Search}
									alt="search-icon"
									height={20}
									style={{ marginLeft: "10px" }}
								/>
								<input
									type="text"
									className="search-input"
									style={{ color: "#fff" }}
									placeholder="Search any terms or phrase..."
									onChange={(e) => setSearch(e.target.value)}
								/>
							</div>

							<input
								type="button"
								value="Search"
								onClick={searchBlogs}
								className="search-btn-g"
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

const LogoutButton = ({ showLanguage, setshowLanguage, user, logoutUser, currentUser }) => {
	const { logoutAlert, setLogoutAlert } = useContext(AppContext);

	return (
		<>
			{/* <div style={{ position: "relative", marginRight: 30 }}>
				<button
					className="internal-btn"
					onClick={() => setshowLanguage(!showLanguage)}
				>
					<span className="material-icons lng-left-ic">public</span>
					<p className="lng-txt-m">English</p>
					<span className="material-icons lng-icon">expand_more</span>
				</button>

				{showLanguage && (
					<div className="floating-div">
						<button
							className="btn-lng-sub"
							onClick={() => setshowLanguage(!showLanguage)}
						>
							<p className="lng-txt">Spanish</p>
						</button>
						<button
							className="btn-lng-sub"
							onClick={() => setshowLanguage(!showLanguage)}
						>
							<p className="lng-txt">French</p>
						</button>
						<button
							className="btn-lng-sub"
							onClick={() => setshowLanguage(!showLanguage)}
						>
							<p className="lng-txt">English (uk)</p>
						</button>
						<button
							className="btn-lng-sub"
							onClick={() => setshowLanguage(!showLanguage)}
						>
							<p className="lng-txt">German</p>
						</button>
						<button
							className="btn-lng-sub"
							onClick={() => setshowLanguage(!showLanguage)}
						>
							<p className="lng-txt">Russia</p>
						</button>
					</div>
				)}
			</div> */}

			{/* </div> */}
			<div style={{ position: "relative" }}>
				{ currentUser.id !== null ?  
					<>
						<button
							onClick={() => {
								setLogoutAlert(!logoutAlert);
							}}
							className="log-btn"
							// style={{
							//   backgroundColor: logoutAlert ? "#C8C8C8" : "#0078AC",
							// }}
						>
							<img alt="icon" src={Building} height={32} />
							<div style={{ marginLeft: "8px", paddingRight: "8px", lineHeight: "18px" }}>
								<p className="user-name">{ currentUser.name }</p>
								<p className="user-address">{ currentUser.address }</p>
							</div>
							<span className="material-icons user-ic">expand_more</span>
						</button>

						{ logoutAlert && (
							<button className="logout-btn" onClick={logoutUser}>
								<span
									className="material-icons"
									style={{ fontSize: 20, color: "#000000" }}
								>
									logout
								</span>
								<p style={{ color: "#000000", marginLeft: "10px" }}>
									Logout
								</p>
							</button>
						)}
					</>
					:
					<div className="header-buttons" style={{ marginLeft: "16px" }}>
						<Link to={"/login"} className="link-sign-in">
							<button className="btn-sign btn-sign-in">Sign In</button>
						</Link>
					</div>
				}				
			</div>
		</>
	);
};
