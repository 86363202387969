import React, { useContext } from "react";
import LogoC from "../../assets/SVG/LogoC.svg";
import "../css/footer.css";
import AppContext from "../../assets/Routes/AppContext";

export default function Footer() {
	const { logoutAlert, setLogoutAlert } = useContext(AppContext);
	const hideAlert = () => {
		if (logoutAlert) {
			setLogoutAlert(false);
		}
	};
	return (
		<div className="footer" onClick={hideAlert}>
			<div className="footer-row">
				<div className="row-right">
					{/* <img alt="logo" src={LogoC} height={30} /> */}
					<a target="_blank" href="https://shifl.com/index/">
						<p className="footer-tabs">Shifex</p>
					</a>
					{/* <a href="#">
						<p className="footer-tabs">Capital</p>
					</a> */}
					<a target="_blank" href="https://calendly.com/shifl">
						<p className="footer-tabs">Schedule a call</p>
					</a>
					<a target="_blank" href="https://shifl.com/#hello">
						<p className="footer-tabs">Contact us</p>
					</a>
				</div>
				<div className="row-right-2">
					<a href="#" className="privacy-policy">
						<p className="footer-p">Privacy Policy </p>
					</a>
					<a href="#">
						<p className="footer-p-terms">Terms & Conditions</p>
					</a>
				</div>
			</div>
			<div className="footer-row2 text-center">
				<div>
					<p className="footer-p" style={{}}>
						© Copyright 2023, All Rights Reserved
					</p>
				</div>
				{/* <div className="btm-sub-row">
					<a href="#" className="privacy-policy">
						<p className="footer-p">Privacy Policy </p>
					</a>
					<a href="#">
						<p className="footer-p-terms">Terms & Conditions</p>
					</a>
				</div> */}
			</div>
		</div>
	);
}