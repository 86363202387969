import React, { useEffect, useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Glossary from "../../Tabs/Glossary";
import VideoTutorials from "../../Tabs/VideoTutorials";
import DispalyWord from "../../Tabs/DispalyWord";
import Page404 from "../../Tabs/Page404";
import Landing from "../../Tabs/Landing";
import Login from "../../Guest/Login";
import "../../Tabs/css/global.css";
import ProtectedRoute from "./RequireAuth";
import Test from "../../Tabs/Test";
import { useAuth } from "./AuthProvider";
import AppContext from "./AppContext";

export default function Index() {
	const { login, setUser } = useAuth();
	const [statusLoading, setStatusLoading] = useState(true);
	const [logoutAlert, setLogoutAlert] = useState(false);

	useEffect(() => {
		checkUser();
	}, []);

	const checkUser = () => {
		try {
			const token = localStorage.getItem("user");
			if (token) {
				login();
				setUser(token);
				setStatusLoading(false);
			} else {
				setStatusLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};
	
	if (statusLoading) {
		return (
			<div>
				<p>Loading ......</p>
			</div>
		);
	}

	const value = {
		logoutAlert,
		setLogoutAlert,
	};

	return (
		<AppContext.Provider value={value}>
			<Routes>
				<Route path="/" element={<Landing />} />
				<Route path="/login" element={<Login />} />
				{/* <Route
					path="/"
					element={
					<ProtectedRoute>
						<VideoTutorials />
					</ProtectedRoute>
					}
				/> */}
				<Route
					path="/tutorial"
					element={
						<ProtectedRoute>
							<VideoTutorials />
						</ProtectedRoute>
					}
				/>
				<Route path="/index" element={<Landing />} />
				<Route
					path="glossary"
					element={
						<ProtectedRoute>
							<Glossary />
						</ProtectedRoute>
					}
				/>
				<Route
					path="imran"
					element={
						<ProtectedRoute>
							<Test />
						</ProtectedRoute>
					}
				/>
				<Route
					path="blog/:id"
					element={
						<ProtectedRoute>
							<DispalyWord />
						</ProtectedRoute>
					}
				/>
				<Route path="*" element={<Page404 />} />
			</Routes>
		</AppContext.Provider>
	);
}