import axios from "axios";
import { envVar } from "../Env/localEnv";

const axiosInstance = axios.create({
    baseURL: envVar.apiUrl,
});
axiosInstance.defaults.headers.common.Accept = "application/json";

axiosInstance.interceptors.request.use(
    (config) => {
        // const token = getToken();
        const token = localStorage.getItem("user_token");
        const auth = token ? `Bearer ${token}` : '';
        config.headers['Authorization'] = auth;
        return config;
    },
    (error) => Promise.reject(error),
    
    // response => {
    //     return response;
    // },
    // async (err) => {
    //     console.log(err, "err")
    //     if (err.message === 'cancel_previous_request' || err.message == undefined) {
    //         return Promise.reject(err); 
    //     }
    //     const { config, response: { status, data } } = err;
    //     const originalRequest = config;

    //     console.log(originalRequest, status, data);

    //     let userToken = localStorage.getItem("user_token");
    //     if (userToken === undefined || userToken === '') {
    //         return Promise.reject(err);
    //     }

    //     // if (originalRequest.url.includes("refresh-token")) {
    //     //     store.dispatch("logout")
    //     //     return Promise.reject(err);
    //     // }

    //     if ( status == 500 || status == 400) {
    //         return Promise.reject(data)
    //     }

    //     // if (status === 401 && data.message.toLowerCase() === "unauthenticated.") {
    //     //     if (!isRefreshing) {
    //     //         isRefreshing = true;
    //     //         store
    //     //         .dispatch("refreshToken")
    //     //         .then(({ status }) => {
    //     //             if (status === 200 || status == 204) {
    //     //                 isRefreshing = false;
    //     //             }
    //     //             let access_token = (localStorage.getItem('user_token')!=='' && localStorage.getItem('user_token')!==null) ? localStorage.getItem('user_token') : ''
    //     //             originalRequest.headers.Authorization = `Bearer ${access_token}`
    //     //             //originalRequest.headers.Authorization = "Bearer " + store.getters.getUserToken
    //     //             return axios(originalRequest)
    //     //             // subscribers = [];
    //     //         })
    //     //         .catch(error => {
    //     //             return Promise.reject(error);
    //     //         });
    //     //     }
    //     // }
    // }
);

export default axiosInstance;

// export default function () {
//     axios.defaults.baseURL = envVar.apiUrl
//     axios.defaults.headers.common.Accept = "application/json"

//     // refresh token by using interceptors
//     // let isRefreshing = false;
//     // let subscribers = [];

//     axios.interceptors.response.use(
//         response => {
//             return response;
//         },
//         async (err) => {
//             console.log(err);
//             if (err.message === 'cancel_previous_request' || err.message == undefined) {
//                 return Promise.reject(err); 
//             }
//             const {
//                 config,
//                 response: { status, data }
//             } = err;

//             const originalRequest = config;
//             const token = localStorage.getItem("user_token");

//             console.log(originalRequest, status, data);

//             if (token === "undefined" || token === '') {
//                 return Promise.reject(err);
//             }

//             // if (originalRequest.url.includes("refresh-token")) {
//             //     store.dispatch("logout")
//             //     return Promise.reject(err);
//             // }

//             // if ( status == 500 || status == 400) {
//             //     return Promise.reject(data)
//             // }

//             // if (status === 401 && data.message.toLowerCase() === "unauthenticated.") {
//             //     if (!isRefreshing) {
//             //         isRefreshing = true;
//             //         store
//             //         .dispatch("refreshToken")
//             //         .then(({ status }) => {
//             //             if (status === 200 || status == 204) {
//             //                 isRefreshing = false;
//             //             }
//             //             let access_token = (localStorage.getItem('user_token')!=='' && localStorage.getItem('user_token')!==null) ? localStorage.getItem('user_token') : ''
//             //             originalRequest.headers.Authorization = `Bearer ${access_token}`
//             //             //originalRequest.headers.Authorization = "Bearer " + store.getters.getUserToken
//             //             return axios(originalRequest)
//             //             // subscribers = [];
//             //         })
//             //         .catch(error => {
//             //             return Promise.reject(error);
//             //         });
//             //     }
//             // }
//         }
//     );
// }