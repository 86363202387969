import React, { useEffect, useState, useContext } from "react";
import "./css/Glossary.css";
// import axios from "axios";
import axiosInstance from "../configs/axios";
import Header from "./Components/Header";
import { Link, json } from "react-router-dom";
import Footer from "./Components/Footer";
import { envVar } from "../Env/localEnv";
import AppContext from "../assets/Routes/AppContext";

export default function Glossary() {
	const { logoutAlert, setLogoutAlert } = useContext(AppContext);

	const [blogs, setBlogs] = useState([]);
	const [search, setSearch] = useState("");
	const [inputState, setInputState] = useState(false);

	useEffect(() => {
		getBlogs();
	}, []);

	useEffect(() => {
		if (search.length == 0 && inputState) {
			getBlogs();
		}
	}, [search]);

	const getBlogs = async () => {
		try {
			const res = await axiosInstance.get(`/get-blogs`);
			setBlogs(res.data.data);
		} catch (error) {
			console.log(error);
		}
	};
	const searchBlogs = async () => {
		try {
			if (search.length < 4) return;
			setInputState(true);
			const url = `/blog/search/${search}`;
			const res = await axiosInstance.get(url);
			if (res.status == 200 && res.data) {
				setBlogs(res.data.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const hideAlert = () => {
		if (logoutAlert) {
			setLogoutAlert(false);
		}
	};

	return (
		<div className="app-container">
			<Header
				title={"Glossary"}
				index={true}
				subTitle={null}
				setSearch={setSearch}
				searchBlogs={searchBlogs}
			/>
			<div className="main-div-g" onClick={hideAlert}>
				{ Object.keys(blogs).map((item, key) => (
					<div key={key} className="main-div-items">
						<p className="alphabet">{item}</p>
						<div className="grid-row">
							{ Object.values(blogs[item]).map(
								(subItem, subKey) => (
									<Item
										subItem={subItem}
										subKey={subKey}
										key={subKey}
									/>
								)
							)}
						</div>
					</div>
				))}
			</div>

			{/* Site Footer */}
			<Footer />
			{/* end */}
		</div>
	);
}
const Item = ({ subItem }) => {
	const [hover, setHover] = useState("");

	const handleHover = () => {
		if (!hover) {
			setHover("hover-item");
			return;
		}
		setHover("");
	};

	return (
		<div className="item-div">
			<Link
				to={`/blog/${subItem.id}`}
				onMouseEnter={handleHover}
				onMouseLeave={handleHover}
				className={`item ${hover}`}
			>
				<p className="item-p">{subItem.name}</p>
			</Link>
		</div>
	);
};
