import React, { useState, useEffect, useContext } from "react";
import "./css/video.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
// import axios from "axios";
import { envVar } from "../Env/localEnv";
import PlaceHolder from "../assets/SVG/placeHolder.svg";
import Search from "../assets/SVG/Search.svg";
import AppContext from "../assets/Routes/AppContext";
import axiosInstance from "../configs/axios";

export default function VideoTutorials() {
	const { logoutAlert, setLogoutAlert } = useContext(AppContext);

	const [showSubVideo, setShowSubVideo] = useState(false);
	const [active, setActive] = useState(null);
	const [search, setSearch] = useState("");
	const [searching, setIsSearching] = useState(false);
	const [tutorials, setTutorials] = useState([]);
	const [playVideo, setPlayingVideo] = useState("");
	const [showCategory, setShowCategory] = useState(null);
	const [parentActive, setParentActive] = useState(null);

	const gettutorials = async () => {
		try {
			const res = await axiosInstance.get(`/get-tutorials`);
			setTutorials(res.data.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		gettutorials();
	}, []);

	useEffect(() => {
		if (search.length == 0 && searching) {
			gettutorials();
		}
	}, [search]);

	const searchVideo = async (word) => {
		try {
			setSearch(word);
			if (word.length < 5) return;
			setIsSearching(true);
			const url = `/tutorial/search/${word}`;
			const res = await axiosInstance.get(url);
			if (res.status == 200 && res.data.data) {
				setTutorials(res.data.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const hideAlert = () => {
		if (logoutAlert) {
			setLogoutAlert(false);
		}
	};	

	const calculateTitle = (title) => {
		let newTitle = title.replace(/_/g," ");
		return newTitle;
	};	

	return (
		<div className="app-container">
			<Header
				title={"Product Demo Videos"}
				index={null}
				subTitle={""}
			/>

			<div className="main-div-video" onClick={hideAlert}>
				{/* <div style={{ margin: "40px 150px", backgroundColor: "#fff" }}> */}
				<div className="main-div">
					<div className="left-div">
						<div className="search-btn">
							<img src={Search} alt="search icon" />
							<input
								type="text"
								className="input-video"
								placeholder="Search any video..."
								onChange={(e) => searchVideo(e.target.value)}
							/>
						</div>

						<div style={{ marginTop: 20 }}>
							<div className={`first-div ${(active === null) ? 'active' : ''}`}>
								<button
									className="overview-button"
									onClick={() => {
										// setShowCategory(null);
										setShowSubVideo(false);
										setActive(null);
										setPlayingVideo("");
										setParentActive(null);
									}}>
									<p className="heading-left">
										An Overview of Shifl Powerful Tools
									</p>
								</button>
							</div>

							<div className="lower-tabs">
								{Object.keys(tutorials).map((item, key) => (
									<div key={key}>
										<button 
											className={`my-button ${(parentActive === key) ? 'active' : ''}`}
											onClick={() => {
												if (showCategory == key) {
													setShowCategory(null);
													setShowSubVideo(false);
													return;
												}
												setShowCategory(key);
												setShowSubVideo(!showSubVideo);
											}}>
											<p className="video-type">{calculateTitle(item)}</p>
											<span className="material-icons custom-icons">
												{" "}
												{showCategory == key
													? "expand_less"
													: "expand_more"}
											</span>
										</button>

										{showCategory == key &&
											Object.values(tutorials[item]).map(
												(subItem, subKey) => (
													<button
														className={`my-button child ${(active === subItem.id) ? 'active' : ''}`}
														style={{
															paddingLeft: "18px",
														}}
														onClick={() => {
															setShowCategory(key);
															setShowSubVideo(true);
															setActive(subItem.id);
															setPlayingVideo(subItem);
															setParentActive(key);
														}}
														key={subKey}>
														<p className={[
															"link ",
															subItem.id ==
															active
																? " active-link "
																: " video-link",
														]}>
															{subItem.title}
														</p>
													</button>
												)
											)}
									</div>
								))}
							</div>
						</div>
					</div>
					<DisplayVideo playVideo={playVideo} /> 
				</div>
			</div>

			{/* Custom Model */}

			{/* ----------------------------------- Footer ----------------------------------- */}
			<Footer />
		</div>
	);
}

const DisplayVideo = ({ playVideo }) => {
	const formatTime = (time) => {
		if (!time) return;
		let date = new Date(time);
		var offset = date.getTimezoneOffset() / 60;
		var hours = date.getHours();
		var newDate = new Date(
			date.getTime() + date.getTimezoneOffset() * 60 * 1000
		);
		newDate.setHours(hours - offset);
		return newDate.toLocaleString("en-us");
	};	

	const calculateYoutubeID = (url) => {
		const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
		const match = url.match(regExp);	
		return (match && match[2].length === 11)
		  ? match[2]
		  : null;
	};

	const calculateImageName = (url) => {
		const tutorial = url.split("/")[1];
		return tutorial;
	};

	return (
		<div className="right-div">
			{/* style={{ paddingRight: "30px", marginLeft: 0, paddingTop: "10px" }} */}
			{playVideo.title ? (
				<div className="video-top-row" style={{ padding: "4px  0 18px" }}>
					<p className="" style={{ margin: "0" }}>
						{ playVideo.title }
					</p>
					<p style={{ marginRight: "10px" }}>
						{ formatTime(playVideo.updated_at) }
					</p>
				</div>
			) : (
				// static data for Overview of Shifl Powerful Tools link - to be changed
				<div className="video-top-row" style={{ padding: "4px 0 18px" }}>
					<p className="" style={{ margin: "0" }}>
						An Overview of Shifl Powerful Tools
					</p>
					<p style={{ marginRight: "10px" }}>
						{ formatTime("2023-06-23 09:57:21") }
					</p>
				</div>
			)}

			<div style={{ alignSelf: "center" }}>
				{/(mp4|mov)$/.test(playVideo.file_type) ? (
					<div className="video-div">
						{playVideo.file_hosted_local ? (
							<video width="100%" height="540" controls>
								<source
									src={`${envVar.apiUrl}/tutorial/load-file/${calculateImageName(playVideo.video)}`}
									type="video/mp4"
								/>
							</video>
						) : (
							<iframe
								allowFullScreen
								width="100%"
								height="480"
								src={`https://www.youtube.com/embed/${playVideo.video}`}
								style={{ alignSelf: "center" }}>								
							</iframe>
						)}
					</div>
				) : /(png|jpeg|jpg|image)$/.test(playVideo.file_type) ? (
					<div className="video-div-img">
						<img
							alt="tutorial"
							style={{ border: "1px solid #f7f7f7", borderRadius: "4px", height: "480px" }}
							src={`${envVar.apiUrl}/tutorial/load-file/${calculateImageName(playVideo.video)}`}
							width="100%"
						/>
					</div>
				) : /(pptx|ppt|pdf)$/.test(playVideo.file_type) ? (
					<iframe
						src={`https://docs.google.com/viewer?embedded=true&url=${envVar.apiUrl}/tutorial/load-file/${playVideo.video}`}
						width="90%"
						height="540"
						style={{ border: "2px solid green" }}
					></iframe>
				) : playVideo.file_type === "link" ? (
					<div className="video-div" style={{ height: "100%" }}>
						<iframe width="100%" height="480" 
							src={`https://www.youtube.com/embed/${calculateYoutubeID(playVideo.youtube_url)}`}
							title="YouTube video player"
							style={{ border: "0" }}
							allowFullScreen>								
						</iframe>
						{/*  */}
					</div>
				) : (
					<div className="video-div" style={{ height: "100%" }}>
						{/* <img src={PlaceHolder} height="10%" width="80%" /> */}
						<img
							alt="placeholder"
							src={PlaceHolder}
							height={480}
							width="80%"
						/>
					</div>
				)}

				{ playVideo.description ? 
					<p className="video-desc">{playVideo.description}</p>
					:
					<></>
				}				
			</div>
		</div>
	);
};
