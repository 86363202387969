import React from "react";

export default function Test() {
	return (
		<div>
			<iframe
				width="560"
				height="315"
				src="https://www.youtube.com/embed/3FjYcMKZ3SU"
				title="YouTube video player"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen
			></iframe>
		</div>
	);
}