import React, { useState, useEffect } from "react";
import Logo from "../../assets/SVG/Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import ShiflLogo from "../../assets/SVG/shifl-logo-white.svg";
import platformIcon from "../../assets/SVG/platform.svg";
import glossaryIcon from "../../assets/SVG/glossary-book.svg";
import chevronRight from "../../assets/SVG/chevron-right-white.svg";
// import Building from "../../assets/SVG/building.svg";
import Search from "../../assets/SVG/Search.svg";
import "../css/LHeader.css";
import { useAuth } from "../../assets/Routes/AuthProvider";

export default function Header({ index, setSearch, searchBlogs, user }) {
	const [showMobileNav, setShowMobileNav] = useState(false);	
	const [screenSize, setScreenSize] = useState(getCurrentDimension());
	const [currentUser, setCurrentUser] = useState(null);

	function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}

	useEffect(() => {
		setCurrentUser(localStorage.getItem("user"));
		const updateDimension = () => {
			  setScreenSize(getCurrentDimension())
		}
		window.addEventListener('resize', updateDimension);	
		return(() => {
			window.removeEventListener('resize', updateDimension);
		})
  	}, [screenSize])

	return (
		<div className="header-landing" style={{ position: "relative", overflow: "hidden" }}>
			<div className="header-content">
				<img src={ShiflLogo} className="water-mark" alt="water-mark" />
				{ currentUser ?
					<></>
					:
					<div className="header-buttons">
						<Link to={"/login"} className="link-sign-in">
							<button className="btn-sign btn-sign-in">Sign In</button>
						</Link>
						{/* <Link to={"/login"} className="link-sign-up">
							<button className="btn-sign btn-sign-up">Sign Up</button>
						</Link> */}
						<button className="btn-sign btn-sign-up">Sign Up</button>
					</div>
				}
			</div>

			<div className="landing-body-wrapper">
				<div className="landing-header-div">
					<p className="sub-heading">Explore Our Platform</p>
					<p className="sub-heading-two">
						Watch the demo videos to experience our platform in action while enhance your 
						understanding with our comprehensive glossary.
					</p>
				</div>

				<div className="landing-platform-showcase">
					<div className="platform-box" style={{marginRight: "8px"}}>
						<div className="platform-header">
							<img src={platformIcon} className="water-mark" alt="platform" />
							<p>Platform Showcase</p>
						</div>
						<p className="platform-desc">
							Watch tutorial videos showcasing how our innovative features can 
							transform your experience and streamline your supply chain workflow.
						</p>
						<div className="landing-btn">
							<Link to={"/tutorial"} className="left-btn">
								<p className="left-btn-txt">Start Watching
									<img src={chevronRight} className="water-mark" alt="chevron-right" />
								</p>
							</Link>
						</div>
					</div>
					<div className="platform-box">
						<div className="platform-header">
							<img src={glossaryIcon} className="water-mark" alt="glossary" />
							<p>Industry Glossary</p>
						</div>
						<p className="platform-desc">
							Access a comprehensive glossary of industry terms, 
							aiding your proficiency in understanding and using terminology within the industry.
						</p>
						<div className="btm-search-div">
							<div className="search-Header-btn">
								<img src={Search} className="water-mark" alt="search" />
								<input
									type="text"
									className="search-input"
									style={{ color: "#fff" }}
									placeholder="Search terms..."
								/>
								{/* onChange={(e) => setSearch(e.target.value)} */}
							</div>

							<Link to={"/glossary"} className="right-btn">
								<p className="right-btn-txt">View All 
									<img src={chevronRight} className="water-mark" alt="chevron-right" />
								</p>
							</Link>
						</div>
					</div>
				</div>
			</div>

			{/* <img src={WaterMark} className="water-mark" alt="water-mark" />
			<div className="header-row-landing">
				<div>
					<img
						alt="logo"
						src={Logo}
						height={30}
						className="shifl-logo"
					/>
				</div>
			</div> */}

			{/* <div className="header-btm-div landing-header-div">
				<p
					className="header-p"
					style={{
						fontSize: 28,
						fontWeight: "400",
						marginTop: "40px",
					}}
				>
					Let's Get Started
				</p>
				<p className="sub-heading">It’s Demo Tutorial Time</p>
				<p className="sub-heading-two">
					Discover the Power of Shifl Software Watch Our Amazing
					Product Demo Video or Explore the Glossary for your
					supply chain terminologies!
				</p>
				<div className="landing-btn">
					<Link to={"/tutorial"} className="left-btn">
						<p className="left-btn-txt">Watch Videos</p>
					</Link>
					<Link to={"/glossary"} className="right-btn">
						<p className="right-btn-txt">Search Glossary</p>
					</Link>
				</div>
			</div> */}
		</div>
	);
}
