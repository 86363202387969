import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

function RequireAuth({ children }) {
	const { user } = useAuth();
	return children;
	// return user ? children : <Navigate to="../login" replace={true} />;
	// return user ? children : <Navigate to="/" replace={true} />;
}

export default RequireAuth;