import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import LogoC from "../assets/SVG/LogoC.svg";
import { useAuth } from "../assets/Routes/AuthProvider";
import axios from "axios";
import axiosInstance from "../configs/axios";
import { envVar } from "../Env/localEnv";

export default function Login() {
	const navigate = useNavigate();
	const { user, setUser } = useAuth();
	const [message, setMessage] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [hidePassword, setHidePassword] = useState(true);

	React.useEffect(() => {
		if (user) {
			navigate("/");
		}
	}, [user, navigate]);

	const loginUser = async (e) => {
		try {
			e.preventDefault();

			const validEmail = validateEmail(email);
			if (!validEmail) {
				setMessage("Please provide a valid email address. ");
				return;
			}
			if (!password.trim()) {
				setMessage("Please Enter password ");
				return;
			}
			const url = `${envVar.authApi}`;
			setLoading(true);
			let data = { email, password };

			const res = await axios.post(url, data, {
				withCredentials: true,
				credentials: "include",
			});
			if (res.status == 200) {
				localStorage.setItem("user", email);
				localStorage.setItem("user_token", res.data.token);
				setUser(email);
				setLoading(false);
				navigate("/");

				const fetchUserDetails = await axiosInstance.post("/details");
				if (fetchUserDetails.status === 200) {
					let details = JSON.stringify(fetchUserDetails.data.success);
					localStorage.setItem("user_details", details);
				}				
			}
		} catch (error) {
			setLoading(false);
			if (error.code === "ECONNABORTED" || error.code === "ERR_NETWORK") {
				setMessage("Check Your connection and try again");
				return;
			}

			if (error.response) {
				if (error.response.data.message) {
					setMessage(
						"The email or password you entered is incorrect"
					);
				}
			}
			console.log(error);
		}
	};
	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};
	return (
		<div className="container">
			<div
				style={{
					marginBottom: "16px",
				}}
			>
				<img alt="shifl log" src={LogoC} height={48} width={150} />
			</div>
			<div className="login-div">
				<form
					onSubmit={loginUser}
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<p className="heading">Sign In to Shifl</p>
					<div>
						<p className="label">EMAIL ADDRESS</p>
						<input
							type="text"
							onChange={(e) => setEmail(e.target.value)}
							className="input"
							placeholder="e.g. abcdefghij@email.com"
						/>
					</div>
					<div className="form-div">
						<p className="label">PASSWORD</p>
						<div className="password_div">
							<input
								onChange={(e) => setPassword(e.target.value)}
								type={hidePassword ? "password" : "text"}
								className="password_input"
								placeholder="Type your password"
							/>
							<button
								type="button"
								className="visibility_btn"
								onClick={() => setHidePassword(!hidePassword)}
							>
								<span className="material-icons visibility_txt">
									{hidePassword
										? "visibility"
										: "visibility_off"}
								</span>
							</button>
						</div>
					</div>
					<div className="form-div-lower">
						<div style={{ display: "flex" }}>
							<input type="checkbox" className="checkbox" />
							<p
								style={{
									marginLeft: 5,
									color: "#4a4a4a",
									fontSize: 14,
								}}
							>
								Remember me
							</p>
						</div>
						<button type="button" className="forget-btn">
							<p style={{ color: "#3D8FB5" }}>Forgot Password?</p>
						</button>
					</div>
					<div className="err-msg">{message}</div>
					<button
						disabled={loading}
						type="submit"
						className="submit-btn"
					>
						{loading ? (
							<div className="lds-dual-ring" />
						) : (
							<p className="submit-txt">Sign In</p>
						)}
					</button>
					{/* <div id="loader-icon" className="loader-icon" /> */}
				</form>
			</div>
		</div>
	);
}

// loader.style.display = "block"
