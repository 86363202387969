import React from "react";
import Header from "./Components/LHeader";
import Footer from "./Components/Footer";
import Imac from "../assets/SVG/iMac.svg";

export default function Landing() {
	return (
		<div className="app-container">
			<Header title={"Glossary"} index={false} />
			<Footer />
		</div>
	);
}